// EditProfilePopup.js
import React, { useEffect, useState } from 'react';
import { db, storage } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import './EditProfile.css'
import './GuestlistView.css'
import './General.css'

const GuestlistView = ({ onClose, value }) => {
    const [event, setEvent] = useState(value);
    const [attendees, setAttendees] = useState([]);
    const [prospectiveAttendees, setProspectiveAttendees] = useState([]);
    const combinedAttendees = [...prospectiveAttendees, ...attendees];


    useEffect(() => {
        const fetchGuests = async () => {
            const db = firebase.firestore();
            const eventDocumentRef = db.collection("events").doc(event.id);
            const membersAttendingRef = eventDocumentRef.collection("membersAttending");

            try {
                const membersSnapshot = await membersAttendingRef.get();
                const attendeesData = [];

                for (const memberDocument of membersSnapshot.docs) {
                    const memberID = memberDocument.id;
                    const membersCollectionRef = db.collection("members");
                    const querySnapshot = await membersCollectionRef.where("uid", "==", memberID).get();

                    for (const memberDoc of querySnapshot.docs) {
                        const memberData = memberDoc.data();
                        const attendee = {
                            id: memberDoc.id,
                            firstname: memberData.firstname,
                            lastname: memberData.lastname,
                            company: memberData.company || "", // Handle null or undefined
                            website: memberData.website || "", // Handle null or undefined
                        };
                        attendeesData.push(attendee);
                    }
                }

                setAttendees(attendeesData);
            } catch (error) {
                console.error("Error fetching attendees:", error.message || "Unknown error");
            }
        };

        fetchGuests();
    }, []);

    useEffect(() => {
        const fetchProspectiveGuests = async () => {
            const db = firebase.firestore();
            const eventDocumentRef = db.collection("events").doc(event.id);
            const prospectivesAttendingRef = eventDocumentRef.collection("prospectivesAttending");

            try {
                const prospectivesSnapshot = await prospectivesAttendingRef.get();
                const prospectiveAttendeesData = [];

                prospectivesSnapshot.forEach((prospectivesDocument) => {
                    const prospectivesData = prospectivesDocument.data();
                    const attendee = {
                        id: prospectivesDocument.id,
                        firstname: prospectivesData.firstname || "",
                        lastname: prospectivesData.lastname || "",
                        company: prospectivesData.company || "",
                        website: "", // You can set this value as needed
                    };
                    prospectiveAttendeesData.push(attendee);
                });

                setProspectiveAttendees(prospectiveAttendeesData);
            } catch (error) {
                console.error("Error fetching prospective guests:", error.message || "Unknown error");
            }
        };

        fetchProspectiveGuests();
    }, []);

    function openWebsiteInNewTab(websiteUrl) {
        // Prepend 'http://' if it's not already included
        if (!websiteUrl.startsWith('http://') && !websiteUrl.startsWith('https://')) {
            websiteUrl = 'http://' + websiteUrl;
        }

        window.open(websiteUrl, '_blank');
    }


    return (
        <div className="guestlist-modal">
            <div className='guestlist-content'>
                <div className="top-bar">
                    <p className='guestTitle'>GUESTLIST</p>
                    <div className="exit-button" onClick={onClose}>
                        <span className="exit-cross">X</span>
                    </div></div>
                <ul className="attendee-list">
                    {combinedAttendees.map((attendee, index) => (
                        <li key={index} className="attendee-item">
                            <div className="attendee-name">{attendee.firstname} {attendee.lastname}</div>
                            {attendee.website ? (
                                <a className='company-link' href="#" onClick={() => openWebsiteInNewTab(attendee.website)}>
                                    {attendee.company}
                                </a>
                            ) : (
                                <div className="attendee-company">{attendee.company}</div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>


        </div>
    );
};

export default GuestlistView;
