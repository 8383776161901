import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Events from './MyEvents';
import Navbar from './NavBar';
import Membership from './Membership'
import Home from './Portal'
import Profile from './Profile'
import Directory from './Directory';
import './index.css'
import MemberProfile from './MemberProfile';
import Resources from './resources';
import RecordingView from './RecordingView';
import EventDetails from './EventDetails'
import LoginPage from './LoginPage';
import Billing from './Billing'

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const showNavbar = location.pathname !== '/login';

  return (
    <>
      {showNavbar && <Navbar />}
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<Home />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/directory" element={<Directory />} />
        <Route path="/member/:memberId" element={<MemberProfile />} />
        <Route path="/events" element={<Events />} />
        <Route path="/event/:eventId" element={<EventDetails />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/resource/:recordingId" element={<RecordingView />} />
        <Route path="/billing" element={<Billing />} />
      </Routes>
    </>
  );
}

export default App;