import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './EventCard.css';
import clock from './eventslideimg/clock@3x.png'
import calendar from './eventslideimg/calendar-date@3x.png'
import pin from './eventslideimg/marker-pin-01@3x.png'
import paymenticon from './eventslideimg/paymentIcon.png'
import { onAuthStateChanged, getAuth } from 'firebase/auth';

const auth = getAuth();

const PastEvents = () => {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState()

    useEffect(() => {
        // Set up the authentication listener
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {

                console.log("user is signed in profile", user)
                // User is signed in
                setUser(user);
                fetchPastEvents(user)

                // Perform any necessary actions for a logged-in user
            } else {
                // User is not signed in
                console.log("user is not signed in")
                setUser(null);
                window.location.href = 'https://curve.club';
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, []);


    const fetchPastEvents = async (user) => {
        try {
            const today = new Date();
            const snapshot = await db.collection('members').where('uid', '==', user.uid).get();
            const memberDoc = snapshot.docs[0];

            if (!memberDoc) {
                console.log('Member document not found');
                setIsLoading(false);
                return;
            }

            const eventIDs = memberDoc.data().events || [];
            const temporaryEvents = [];

            console.log("eventIDs", eventIDs)

            await Promise.all(
                eventIDs.map(async (eventID) => {
                    const eventDoc = await db.collection('events').doc(eventID).get();

                    if (eventDoc.exists) {
                        const eventData = eventDoc.data();
                        const dateTimestamp = eventData.startDatetime;
                        // Check if the event date is greater than today
                        if (dateTimestamp instanceof firebase.firestore.Timestamp) {
                            const date = dateTimestamp.toDate();
                            // Check if the event date is before today
                            if (date < today) {

                                console.log("starttime", eventData.startDatetime.seconds)

                                temporaryEvents.push({
                                    id: eventDoc.id,
                                    title: eventData.title,
                                    description: eventData.description,
                                    startDatetime: eventData.startDatetime,
                                    endDatetime: eventData.endDatetime,
                                    date: eventData.date,
                                    price: eventData.price,
                                    location: eventData.location,
                                    address: eventData.address,
                                    url: eventData.url,
                                });
                            }
                        } else {
                            console.log('Invalid date format for event:', eventDoc.id);
                        }
                    }
                })
            );



            // Sort the events by date
            temporaryEvents.sort((a, b) => b.date - a.date);


            console.log("temporaryEvents", temporaryEvents)

            setEvents(temporaryEvents);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events:', error);
            setIsLoading(false);
        }
    };


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="event-slide">
                {events.map((event) => (
                    <div key={event.id} className="event-card">
                        <img src={event.url} alt="Event" className="event-image" />
                        <div className="format-details">
                            <div className="event-details">
                                <div className='event-title-container-123'>
                                    <h3 className='event-title'>{event.title}</h3>
                                </div>
                                <div className='vertical-container'>

                                    <div className="details-container">
                                        <img src={calendar} alt="clock" className='event-icons' />
                                        {event.date && event.startDatetime.seconds && (
                                            <p>{new Date(event.startDatetime.seconds * 1000).toLocaleString("en-US", { dateStyle: "long" })}</p>
                                        )}
                                    </div>
                                    <div className="details-container">
                                        <img src={clock} alt="clock" className='event-icons' />
                                        <p>
                                            {new Date(event.startDatetime.seconds * 1000).toLocaleString("en-US", { timeStyle: "short" })}
                                            -
                                            {new Date(event.endDatetime.seconds * 1000).toLocaleString("en-US", { timeStyle: "short" })}
                                        </p>
                                    </div>
                                    <div className="details-container">
                                        <img src={pin} alt="pin" className='event-icons' />
                                        <p>{event.location}</p>
                                    </div>
                                    <div className="details-container">
                                        <img src={paymenticon} alt="paymenticon" className='event-icons' />
                                        <p>{event.price}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PastEvents;
