import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './EventCard.css';
import './Membership.css'
import './General.css'
import RecordingCard from './RecordingCard'

function Resources() {
    const [recordings, setRecordings] = useState([]);

    const backgroundImage = "https://firebasestorage.googleapis.com/v0/b/curveclub-68421/o/MemershipCardTemplate.png?alt=media&token=5ce366db-2b0c-4480-8618-9921d314e7dc"

    useEffect(() => {

        const fetchRecordings = async () => {
            try {
                const recordingCollection = await db.collection('event-recordings').get();

                const recordingsArray = [];

                if (!recordingCollection.empty) {
                    recordingCollection.forEach((recordingDoc) => {
                        const recordingData = recordingDoc.data();

                        recordingData["fid"] = recordingDoc.id;
                        recordingsArray.push(recordingData);
                    });

                    // Sort the recordingsArray in descending order by date
                    const sortedRecordings = recordingsArray.sort((a, b) => {
                        // Check if 'date' exists and is a Firestore Timestamp on both objects
                        const dateA = a.date && a.date.toDate ? a.date.toDate() : new Date(0); // Default to epoch if invalid
                        const dateB = b.date && b.date.toDate ? b.date.toDate() : new Date(0); // Default to epoch if invalid

                        return dateB - dateA;
                    });


                    // console.log("All recordings:", sortedRecordings);
                    setRecordings(sortedRecordings);
                }
            } catch (error) {
                console.error('Error retrieving events:', error);
            }
        };
        fetchRecordings();
    }, []);

    return (
        <div className='portal-decoration'>
            <h2 className='space-dec'>RECORDINGS</h2>

            <div className='recordings'>

                {recordings.map((recordingData, index) => (
                    <Link to={{
                        pathname: `/resource/${recordingData.fid}`,
                        state: { recordingData } // Pass the memberData object as state directly
                    }}

                        style={{
                            "text-decoration": "none"
                        }}

                        key={recordingData.fid}>
                        <RecordingCard key={index} value={recordingData}></RecordingCard>
                    </Link>
                ))
                }

            </div>
        </div >
    );
}

export default Resources;