import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link, useLocation, useParams } from 'react-router-dom';
import './RecordingView.css';
import './General.css'
import TabletContainerView from './TabletContainerView';

function RecordingView() {
    const [recording, setRecording] = useState();

    const location = useLocation();
    const { recordingId } = useParams();

    useEffect(() => {
        const fetchRecording = async () => {


            console.log("recordingId", recordingId)

            try {

                const recordingDoc = await db.collection('event-recordings').doc(recordingId).get();

                if (recordingDoc.exists) {

                    setRecording(recordingDoc.data())

                }

            }
            catch (error) {
                console.error('Error retrieving events:', error);
            }
        };

        fetchRecording();
    }, []);

    return (
        <div className='portal-decoration'>
            {recording && (
                <div>
                    <h2 className='space-dec'>{recording.title.toUpperCase()}</h2>

                    <video controls>
                        <source src={recording.recording_url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <div className='recording-container'>
                        <p className='recording-title'>FEATURING</p>
                        <p>{recording.featuring}</p>
                        <p className='recording-title'>INTERVIEWER</p>
                        <p>{recording.moderator}</p>
                        <p className='recording-title'>DESCRIPTION</p>
                        <p>{recording.description}</p>
                    </div>
                </div>


            )}



        </div>
    );
}

export default RecordingView;