import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link, useLocation, useParams } from 'react-router-dom';
import './EventCard.css';
import Restaurants from './restaurants';
import Events from './Events';
import './Profile.css'
import './General.css'
import TabletContainerView from './TabletContainerView';

function MemberProfile() {
    const [member, setMember] = useState();
    const [showEditPopup, setShowEditPopup] = useState(false);

    const location = useLocation();
    const { memberId } = useParams();

    const backgroundImage = "https://firebasestorage.googleapis.com/v0/b/curveclub-68421/o/profile_image_placeholder.png?alt=media&token=a0d78aa8-9337-47ac-b1ad-34c35c1a451e"

    const handleClick = () => {
        const memberEmail = member.email; // Replace with the actual email address of the member

        const subject = `${member.firstname} <>`; // Replace with the desired subject of the email
        const body = `Hi ${member.firstname},`; // Replace with the desired body of the email

        // Encode the subject and body for the mailto URL
        const encodedSubject = encodeURIComponent(subject);
        const encodedBody = encodeURIComponent(body);

        // Compose the mailto URL with subject and body
        const mailtoUrl = `mailto:${memberEmail}?subject=${encodedSubject}&body=${encodedBody}`;

        // Open the user's default email client with the pre-filled email
        window.open(mailtoUrl);
    };


    const handleCoffeeClick = () => {
        const memberEmail = member.email; // Replace with the actual email address of the member

        const subject = `Coffee Request: ${member.firstname} <>`; // Replace with the desired subject of the email
        const body = `Hi ${member.firstname}, \n\n it would be great to connect and grab a coffee/zoom as..`; // Replace with the desired body of the email

        // Encode the subject and body for the mailto URL
        const encodedSubject = encodeURIComponent(subject);
        const encodedBody = encodeURIComponent(body);

        // Compose the mailto URL with subject and body
        const mailtoUrl = `mailto:${memberEmail}?subject=${encodedSubject}&body=${encodedBody}`;

        // Open the user's default email client with the pre-filled email
        window.open(mailtoUrl);
    };



    useEffect(() => {
        const fetchMember = async () => {
            try {
                const memberDoc = await db.collection('members').doc(memberId).get();

                if (memberDoc.exists) {

                    setMember(memberDoc.data())

                }

            }
            catch (error) {
                console.error('Error retrieving events:', error);
            }
        };

        fetchMember();
    }, []);

    return (
        <div className='portal-decoration'>
            {member && (
                <div className='profile-container'>

                    <div className='col'>
                        {/* IMAGE AND TEXT */}
                        <div
                            className="square-with-background"
                            style={{
                                backgroundImage: `url(${member.image})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <div className="overlay" />
                            <div className="text-container">
                                <p className="text">{member.firstname} {member.lastname}</p>
                                <p className="text">{member.title}</p>
                                <p className="text">{member.company}</p>
                            </div>
                        </div>
                        {/* HEADLINE INFORMATION */}
                        <div>
                            <div className="horizontal-line-divider">
                                <hr />
                            </div>

                            <div className='headline-container'>

                                <div className='headline-information'>
                                    {member.profile_questions && (
                                        <p className="question-text">
                                            {member.membership_zone === 'entrepreneur'
                                                ? 'BUSINESS MODEL'
                                                : member.member_zone === 'investor'
                                                    ? 'CLASSIFICATION'
                                                    : 'Default Text'}
                                        </p>
                                    )}
                                    {member.profile_questions && (
                                        <p className="profile-text">
                                            {member.membership_zone === 'entrepreneur'
                                                ? member.profile_questions["Business Type"].join(', ')
                                                : member.member_zone === 'investor'
                                                    ? member.profile_questions["Role"]
                                                    : null // or any other fallback value or component if needed
                                            }
                                        </p>
                                    )}
                                </div>

                                <div className='headline-information'>
                                    {member.profile_questions && (
                                        <p className="question-text">
                                            {member.membership_zone === 'entrepreneur'
                                                ? 'FUNDING STAGE'
                                                : member.member_zone === 'investor'
                                                    ? 'CAPITAL DEPLOYED'
                                                    : 'Default Text'}
                                        </p>
                                    )}
                                    {member.profile_questions && (
                                        <p className="profile-text">
                                            {member.membership_zone === 'entrepreneur'
                                                ? member.profile_questions["Funding Stage"]
                                                : member.member_zone === 'investor'
                                                    ? member.profile_questions["Capital Raised"]
                                                    : null // or any other fallback value or component if needed
                                            }
                                        </p>
                                    )}

                                </div>

                            </div>

                            <div className="horizontal-line-divider">
                                <hr />
                            </div>
                        </div>
                        {/* BIO */}
                        <div>
                            <h5>BIO</h5>
                            <p className="profile-text-bio">{member.bio}</p>
                        </div>

                        <div className='black-button' onClick={handleClick}>
                            EMAIL
                        </div>


                    </div>
                    {/* 
                    <div className="vertical-line-divider" />

                    {/* SECOND COLUMN */}
                    <div className='col2'>
                        <div className="main-container">
                            {Object.entries(member.profile_questions ?? {}).sort((a, b) => a[0].localeCompare(b[0])).map(([question, answers]) => (
                                <div key={question} className="vstack-item">
                                    <h2 className="question-text">{question.toUpperCase()}</h2>
                                    {Array.isArray(answers) ? (
                                        answers.map((answer, index) => (
                                            <TabletContainerView key={index} word={answer} isSelected={false} />
                                        ))
                                    ) : (
                                        <TabletContainerView word={answers} isSelected={false} />
                                    )}
                                </div>
                            ))}
                        </div>


                    </div>

                </div>
            )}



        </div>
    );
}

export default MemberProfile;