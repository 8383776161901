import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './EventCard.css';
import './Membership.css'
import './General.css'
import { onAuthStateChanged, getAuth } from 'firebase/auth';

const auth = getAuth();

function Billing() {
    const [events, setEvents] = useState([]);
    const [member, setMember] = useState();
    const [user, setUser] = useState();

    useEffect(() => {
        // Set up the authentication listener
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {

                console.log("user is signed in profile", user)
                // User is signed in
                setUser(user);
                fetchMember(user)

                // Perform any necessary actions for a logged-in user
            } else {
                // User is not signed in
                console.log("user is not signed in")
                setUser(null);
                window.location.href = 'https://curve.club';
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, []);



    const fetchMember = async (user) => {

        const memberRef = db.collection('members').where('uid', '==', user.uid);


        try {
            const querySnapshot = await memberRef.get();

            if (!querySnapshot.empty) {

                const document = querySnapshot.docs[0];
                const member = document.data();
                console.log("the member", member)
                setMember(member)
            }
        } catch (error) {
            console.error('Error getting member document:', error);
        }

    };

    const openStripeBilling = () => {
        window.open('https://billing.stripe.com/p/login/4gwaGUbZr2qZ7sY288', '_blank');
    };



    return (
        <div className='portal-decoration'>
            <h2 className='space-dec'>BILLING</h2>
            {member && (
                <div className='register-button' onClick={openStripeBilling}>
                    CARD INFO
                </div>
            )}
            <br></br>
            <p className="small-bold-text">Need support? Email memberships@curve.club</p>


        </div>
    );
}

export default Billing;