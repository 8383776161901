import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './restaurants.css';
import clock from './eventslideimg/clock@3x.png'
import calendar from './eventslideimg/calendar-date@3x.png'
import pin from './eventslideimg/marker-pin-01@3x.png'

function Restaurants() {
    const [restaurants, setRestaurants] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const restaurantCollection = db.collection("restaurants");
                const snapshot = await restaurantCollection.get();
                const fetchedRestaurants = snapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))

                console.log('Fetched restaurants:', fetchedRestaurants);
                setRestaurants(fetchedRestaurants);
            } catch (error) {
                console.error('Error retrieving events:', error);
            }
        };

        fetchEvents();
    }, []);

    return (
        <div>
            <div className="restaurant-slide">
                {restaurants.map((restaurant) => (
                    <a
                        href={`mailto:memberships@curve.club?subject=Your%20Subject&body=Hello%20there,%0A%0AI%20wanted%20to%20get%20in%20touch%20with%20you%20about%20${restaurant.restaurant}%20restaurant.`}
                        key={restaurant.id}>
                        <div className="restaurant-card">
                            <div className="format-details-restaurant">
                                <p className='restaurant-text'>{restaurant.restaurant.toUpperCase()}</p>
                            </div>
                            <img src={restaurant.image} alt="Restaurant" className="restaurant-image" />
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default Restaurants;