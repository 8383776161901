
import React, { useEffect, useState } from 'react';
import './TabletContainerView.css';



const TabletContainerView = ({ word, isSelected, onClick }) => {
    return (
        <div
            className={`tablet-container ${isSelected ? 'selected' : ''}`}
            onClick={onClick}
        >
            {word}
        </div>
    );
};

export default TabletContainerView;