import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './EventCard.css';
import './Directory.css'
import './General.css'
import MemberDirectoryCard from './MemberDirectoryCard';
import Select from 'react-select';

function Directory() {
    const [events, setEvents] = useState([]);
    const [member, setMember] = useState();
    const [members, setMembers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [possibleQuestions, setPossibleQuestions] = useState([]);
    const [possibleAnswers, setPossibleAnswers] = useState([]);
    const [selectedQuestionAnswers, setSelectedQuestionAnswers] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [selectedAnswerValues, setSelectedAnswerValues] = useState({});
    const [showFilter, setShowFilter] = useState(false);


    useEffect(() => {
        // Fetch the "founder" and "investor" documents from the "onboarding_questions" collection
        const fetchOnboardingQuestions = async () => {
            try {
                const founderDoc = await db.collection('onboarding_profiles').doc('founder').get();
                const investorDoc = await db.collection('onboarding_profiles').doc('investor').get();

                if (founderDoc.exists && investorDoc.exists) {
                    const founderData = founderDoc.data();
                    const investorData = investorDoc.data();

                    const combinedQuestions = [];

                    founderData.questions.forEach(founderQuestion => {
                        const correspondingInvestorQuestion = investorData.questions.find(
                            investorQuestion => investorQuestion.saveAs === founderQuestion.saveAs
                        );

                        if (correspondingInvestorQuestion) {
                            // Merge answers if questions have the same "saveAs"
                            const combinedAnswers = [...founderQuestion.answers, ...correspondingInvestorQuestion.answers];
                            combinedQuestions.push({
                                saveAs: founderQuestion.saveAs,
                                answers: combinedAnswers
                            });
                        } else {
                            // Add founder question as it is
                            combinedQuestions.push(founderQuestion);
                        }
                    });

                    // Add investor questions that are not in founder questions
                    investorData.questions.forEach(investorQuestion => {
                        if (!founderData.questions.some(founderQuestion => founderQuestion.saveAs === investorQuestion.saveAs)) {
                            combinedQuestions.push(investorQuestion);
                        }
                    });

                    console.log("Combined questions:", combinedQuestions);
                    setPossibleQuestions(combinedQuestions);
                }
            } catch (error) {
                console.error('Error fetching onboarding questions:', error);
            }
        };

        fetchOnboardingQuestions();
    }, []);

    const handleQuestionSelect = (question) => {
        setSelectedQuestion(question);
        console.log("question", question)
        const selectedQuestionData = possibleQuestions.find(q => q.saveAs === question);

        console.log("selectedQuestionData", selectedQuestionData)

        if (selectedQuestionData) {
            setPossibleAnswers(selectedQuestionData.answers);
            setSelectedAnswers([]);
        }
    };


    const handleAnswerSelect = (question, selectedValues) => {
        const selectedValueArray = selectedValues.map(option => option.value);
        setSelectedAnswers(prevSelectedAnswers => ({
            ...prevSelectedAnswers,
            [question]: selectedValues,
        }));
        setSelectedAnswerValues(prevSelectedAnswerValues => ({
            ...prevSelectedAnswerValues,
            [question]: selectedValueArray,
        }));
    };

    // const handleAnswerSelect = (question, selectedValues) => {
    //     console.log(question, selectedValues);

    //     const selectedValuesArray = selectedValues.map(option => option.value); // Extract values
    //     setSelectedAnswers(prevSelectedAnswers => ({
    //         ...prevSelectedAnswers,
    //         [question]: selectedValuesArray,
    //     }));
    // };


    const handleSearch = (event) => {
        console.log("search", event.target.value.toLowerCase())
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
    };

    const sortedMembers = filteredMembers.sort((a, b) => {
        const nameA = a.firstname.toLowerCase();
        const nameB = b.firstname.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        const applyFilters = () => {
            const newFilteredMembers = members.filter(member => {
                // Check if the member's name or company matches the search query
                const searchMatch = (
                    member.firstname.toLowerCase().includes(searchQuery) ||
                    (member.company && member.company.toLowerCase().includes(searchQuery))
                );

                // Check if the member matches selected answer values
                const answerValuesMatch = Object.keys(selectedAnswerValues).every(question => {
                    if (selectedAnswerValues[question].length === 0) {
                        return true; // No filters applied for this question
                    }

                    if (member.profile_questions != undefined) {
                        const memberAnswers = member.profile_questions[question] ?? [];
                        return memberAnswers.some(answer => selectedAnswerValues[question].includes(answer));
                    }

                    return false;
                });

                return searchMatch && answerValuesMatch;
            });

            setFilteredMembers(newFilteredMembers);
        };

        applyFilters();
    }, [selectedOptions, searchQuery, selectedAnswerValues, members]);





    useEffect(() => {
        const fetchAllMembers = async () => {
            try {
                const membersCollection = await db.collection('members').where('active', '==', true).get();


                const membersArray = []; // Array to store all members

                if (!membersCollection.empty) {
                    membersCollection.forEach((memberDoc) => {
                        // Assuming each memberDoc contains a 'data()' method
                        const memberData = memberDoc.data();
                        memberData["fid"] = memberDoc.id
                        membersArray.push(memberData);
                    });

                    console.log("All members:", membersArray);
                    setMembers(membersArray)

                    // Now you can use 'membersArray' as needed
                }
            } catch (error) {
                console.error('Error retrieving members:', error);
            }
        };

        fetchAllMembers();
    }, []);

    return (
        <div className='directory'>
            <h2 className='space-dec'>THE MEMBERS DIRECTORY</h2>

            {/* FILTER */}
            <div className={`filter-container ${showFilter ? 'show' : ''}`}>
                {possibleQuestions.map((questionData, index) => (
                    <div className='filter-dropdown' key={index}>
                        <label className='filter-label'>{questionData.saveAs}</label>
                        <Select
                            isMulti
                            options={questionData.answers.map(answer => ({ value: answer, label: answer }))}
                            value={selectedAnswers[questionData.saveAs] || []}
                            onChange={selectedValues => handleAnswerSelect(questionData.saveAs, selectedValues)}
                        />
                    </div>
                ))}
            </div>
            {/* FILTER */}

            <div className='mobileFilter'>

                <div className='search-container'>


                    <input
                        type='text'
                        placeholder='Search by name or company...'
                        onChange={handleSearch}
                    />
                </div>

                <div className='mobileFilterButton' onClick={() => setShowFilter(!showFilter)}>
                    {showFilter ? 'Hide Filter' : 'Show Filter'}
                </div>
            </div>



            <div className='members'>
                {sortedMembers.length > 0 ? (
                    sortedMembers.map((memberData, index) => (
                        <Link
                            to={{
                                pathname: `/member/${memberData.fid}`,
                                state: { memberData }
                            }}
                            key={memberData.fid}
                        >
                            <MemberDirectoryCard
                                key={index}
                                value={memberData}
                                className='member-card'
                            />
                        </Link>
                    ))
                ) : (
                    <p>Loading...</p>
                )}
            </div>

        </div>
    );
}

export default Directory;