import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './EventCard.css';
import './LoginPage.css'
import './General.css'
import { onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getFirestore, collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import SignupPopup from './Signup'

function LoginPage() {
    const [events, setEvents] = useState([]);
    const [member, setMember] = useState(null);
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fail, setFail] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showSignupPopup, setShowSignupPopup] = useState(false);

    const backgroundImage = "https://firebasestorage.googleapis.com/v0/b/curveclub-68421/o/MemershipCardTemplate.png?alt=media&token=5ce366db-2b0c-4480-8618-9921d314e7dc"

    const auth = getAuth();

    const handleSignupClick = () => {
        setShowSignupPopup(true);
    };

    const handleClosePopup = () => {
        setShowSignupPopup(false);
    };



    const forgotPassword = async () => {

        if (email != "") {

            sendPasswordResetEmail(auth, email)
                .then(() => {
                    alert("A password reset email has now been sent to you")
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorMessage)
                    // ..
                });

        }
        else {

            alert("Please enter your email and click forgot password")

        }


    }

    const checkIfMember = async () => {
        const memberRef = collection(db, "members");


        if (email != undefined) {

            const q = query(
                memberRef,
                where("email", "==", email.toLowerCase()),
                where("active", "==", true)
            );

            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                console.log("is member", querySnapshot.docs[0].data());
                setLoading(false);
                setEmailSent(true);
                authenticateUser();
            } else {
                console.log("is not member");
                alert("You must be a member of Curve Club to sign in")
            }

        }


    }

    const authenticateUser = async () => {
        console.log("authenticating user", email);

        if (password == "" || password == null) {
            alert("Please enter your password")
        }
        else {

            try {
                setLoading(true)

                const userCredential = await signInWithEmailAndPassword(auth, email, password);

                // User signed in
                const user = userCredential.user;
                console.log("User signed in:", user);
                window.location.href = '/';
                setLoading(false)
                // You can perform any additional actions for a signed-in user here
            } catch (error) {
                // Handle sign-in errors
                alert("Error signing in: incorrect password/password not recognized")
                console.log("issue")
                console.error("Sign-in error:", error.message);
            }

        }


    };

    useEffect(() => {
        // Set up the authentication listener
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {

                console.log("user is signed in")
                // User is signed in
                setMember(user);

                // Perform any necessary actions for a logged-in user
            } else {
                // User is not signed in
                console.log("user is not signed in")
                setMember(null);
                // window.location.href = '/login';
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, []);


    return (
        <div className='portal-decoration'>
            <h2 className='space-dec'>LOGIN</h2>
            <div className="login-container">
                <div>
                    <input
                        className="input-field"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} // Update the email state as the user types
                    />
                    <input
                        className="input-field"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div onClick={forgotPassword}><p className='forgot-password'>Forgot Password?</p></div>

                    {/* {loading ? (
                        <div className="loading-spinner">Loading...</div>
                    ) : (
                        <>
                            <div onClick={checkIfMember} className="black-button">SUBMIT</div>
                            <div onClick={handleSignupClick}><p className='forgot-password'>First time logging in? Sign up</p></div>
                        </>
                    )} */}


                    <div onClick={checkIfMember} className="black-button">SUBMIT</div>
                    <div onClick={handleSignupClick}><p className='forgot-password'>First time logging in? Sign up</p></div>
                    {showSignupPopup && <SignupPopup onClose={handleClosePopup} />}
                </div>
            </div>

        </div>
    );
}

export default LoginPage;