import React from 'react';
import './NavBar.css'

function HamburgerIcon({ onClick }) {
    return (
        <div className="hamburger-icon" onClick={onClick}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
        </div>
    );
}

export default HamburgerIcon;