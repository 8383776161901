import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getAuth } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDrkrg6O5A2V_1uk9fMiUDJOascZcfvUBk",
    authDomain: "curveclub-68421.firebaseapp.com",
    projectId: "curveclub-68421",
    storageBucket: "curveclub-68421.appspot.com",
    messagingSenderId: "985129645569",
    appId: "1:985129645569:web:40d5198dbeda9618257200",
    measurementId: "G-PPGZ61Q7KS"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);

// const auth = getAuth();


export const db = firebase.firestore(); // Export the Firestore instance
export const storage = firebase.storage();



export default firebase;