// Navbar.js
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './NavBar.css';
import CurveLogo from './CurveLogo.png'
import HamburgerIcon from './HamburgerIcon';
import MobileMenu from './MobileMenu';
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const auth = getAuth();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const logout = async () => {
        try {
            await auth.signOut(); // Sign the user out
            console.log("User logged out successfully");
            window.location.href = '/login';
            // You can also redirect the user to a different page or perform other actions here
        } catch (error) {
            console.error("Error logging out:", error);
        }
    }

    const closeMobileMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <div>
            <nav className="navbar">

                <div className="nav-container">
                    <HamburgerIcon onClick={toggleMenu} />
                    {/* <div className="logo-container">
                    <img src={CurveLogo} className="logo" alt="Logo" />
                </div> */}
                    <div className="nav-links-container">
                        <ul className="nav-links">
                            <div className="logo-container">
                                <img src={CurveLogo} className="logo" />
                            </div>
                            <li>
                                <NavLink exact to="/" className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                                    HOME
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/profile"
                                    className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                                    PROFILE
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/membership" className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                                    MEMBERSHIP
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/directory" className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                                    DIRECTORY
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/events" className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                                    MY EVENTS
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/resources" className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                                    RECORDINGS
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/billing" className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                                    BILLING
                                </NavLink>
                            </li>
                            <li>
                                <a href="https://www.curve.club/register-guest" target="_blank" rel="noopener noreferrer" className="non-active-class">
                                    GUESTS
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div onClick={logout} className="black-button-logout">LOG OUT</div>
                </div>

                {isMenuOpen && <MobileMenu closeMenu={closeMobileMenu} />}
            </nav >
        </div>



    );
};

export default Navbar;
