import React, { useEffect, useState } from 'react';
import { db, storage } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import TabletContainerView from './TabletContainerView';
import './EditProfile.css'

const OnboardingQuestions = (props) => {
    const [questions, setQuestions] = useState([]);
    const [answerOptions, setAnswerOptions] = useState([]);
    const [multichoice, setMultichoice] = useState([]);
    const [saveAs, setSaveAs] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const currentMember = props.value;
    const { value, onUpdate } = props;

    const handleAnswerClick = (question, word) => {

        console.log("we got here", question, word)

        const questionAnswers = selectedAnswers[saveAs[question]] || new Set();


        if (questionAnswers.has(word)) {
            questionAnswers.delete(word);
        } else if (multichoice[question]) {
            console.log("multichoice hi")
            questionAnswers.add(word);
        } else {
            console.log("going here")
            questionAnswers.clear();
            questionAnswers.add(word);
        }

        console.log("question", questions[question])
        console.log("word", word)
        console.log("multichoice", multichoice)

        setSelectedAnswers((prevSelectedAnswers) => ({
            ...prevSelectedAnswers,
            [saveAs[question]]: questionAnswers,
        }));

        console.log("currentselectedanswers", selectedAnswers[saveAs[question]], [questions[question]])


        onUpdate(selectedAnswers);

    };


    const fetchQuestionsFromFirebase = () => {
        const db = firebase.firestore();


        if (!currentMember) {
            return;
        }

        db.collection('onboarding_profiles')
            .doc(currentMember.membership) //change this to currentmember.membership
            .get()
            .then((snapshot) => {
                if (!snapshot.exists) {
                    console.log('No onboarding profile found');
                    return;
                }

                const documentData = snapshot.data();
                if (!documentData || !documentData.questions) {
                    console.log('No questions found');
                    return;
                }

                const fetchedQuestions = [];
                const fetchedSaveAs = [];
                const fetchedAnswerOptions = [];
                const fetchedMultichoice = [];

                documentData.questions.forEach((questionData) => {
                    const question = questionData.question;
                    const saveAs = questionData.saveAs;
                    const answerOptions = questionData.answers;
                    const multichoice = questionData.multichoice;

                    fetchedQuestions.push(question);
                    fetchedSaveAs.push(saveAs);
                    fetchedAnswerOptions.push(answerOptions);
                    fetchedMultichoice.push(multichoice);
                });

                setQuestions(fetchedQuestions);
                setSaveAs(fetchedSaveAs);
                setAnswerOptions(fetchedAnswerOptions);
                setMultichoice(fetchedMultichoice);

                if (currentMember.profile_questions) {
                    const selectedAnswersData = {};

                    console.log(currentMember.profile_questions)

                    const profileQuestions = currentMember.profile_questions

                    Object.entries(profileQuestions).forEach(([question, answers]) => {

                        console.log("now", question, answers)

                        const index = saveAs.findIndex((q) => q === question);
                        console.log("question", question, saveAs[index])

                        // if (index !== -1) {
                        selectedAnswersData[question] = new Set(Array.isArray(answers) ? answers : [answers]);
                        // }
                    });

                    // selectedAnswers[saveAs[question]]

                    console.log("selectedAnswersData", selectedAnswersData)
                    setSelectedAnswers(selectedAnswersData);
                }
            })
            .catch((error) => {
                console.log('Error fetching onboarding profile:', error);
            });
    };

    useEffect(() => {
        fetchQuestionsFromFirebase();
    }, []);

    return (
        <div className="main-container">
            {Object.entries(answerOptions ?? {}).sort((a, b) => a[0].localeCompare(b[0])).map(([question, answers]) => {
                const selectedAnswersForQuestion = selectedAnswers[saveAs[question]] || new Set();

                return (
                    <div key={question} className="vstack-item">
                        <p className="question">{questions[question].toUpperCase()}</p>
                        {answers.map((answer, index) => (
                            <TabletContainerView
                                key={index}
                                word={answer}
                                isSelected={selectedAnswersForQuestion.has(answer)}
                                onClick={() => handleAnswerClick(question, answer)}
                            />
                        ))}
                    </div>
                );
            })}
        </div>

    )
};

export default OnboardingQuestions;
