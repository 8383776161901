import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './EventCard.css';
import './LoginPage.css'
import './General.css'
import { onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getFirestore, collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signInWithEmailAndPassword, sendPasswordResetEmail, createUserWithEmailAndPassword } from "firebase/auth";
import SignupPopup from './Signup'

function LoginPage({ onClose }) {
    const [events, setEvents] = useState([]);
    const [member, setMember] = useState(null);
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fail, setFail] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showSignupPopup, setShowSignupPopup] = useState(false);

    const backgroundImage = "https://firebasestorage.googleapis.com/v0/b/curveclub-68421/o/MemershipCardTemplate.png?alt=media&token=5ce366db-2b0c-4480-8618-9921d314e7dc"

    const auth = getAuth();

    const handleSignupClick = () => {
        setShowSignupPopup(true);
    };

    const handleClosePopup = () => {
        setShowSignupPopup(false);
    };

    const signupUser = async () => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log("userid", user.uid)
                saveUserUID(email, user.uid)
                alert("Your account has been created! Enter your new credentials into our login page and you will be able to access the members portal")
                onClose()

                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorMessage)
                // ..
            });


    }

    const saveUserUID = async (email, uid) => {
        const membersRef = collection(db, "members");
        const q = query(membersRef, where("email", "==", email));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (doc) => {
            const docRef = doc.ref;

            try {
                await updateDoc(docRef, { uid: uid });
                console.log("Field 'uid' added to document:", doc.id);
            } catch (error) {
                console.error("Error updating document:", error);
            }
        });
    };

    const checkIfMember = async () => {
        const memberRef = collection(db, "members");


        if (email != undefined) {

            const q = query(
                memberRef,
                where("email", "==", email.toLowerCase()),
                where("active", "==", true)
            );

            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                console.log("is member", querySnapshot.docs[0].data());
                setLoading(false);
                setEmailSent(true);
                signupUser();
            } else {
                console.log("is not member");
                alert("You must be a member of Curve Club to sign up")
            }

        }


    }



    useEffect(() => {
        // Set up the authentication listener
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {

                console.log("user is signed in")
                // User is signed in
                setMember(user);

                // Perform any necessary actions for a logged-in user
            } else {
                // User is not signed in
                console.log("user is not signed in")
                setMember(null);
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, []);


    return (
        <div className="edit-profile-modal">
            <div className="edit-profile-content">

                <div className="login-container-signup">
                    <h2 className='space-dec-signup'>SIGN UP</h2>
                    <div className="exit-button-signup" onClick={onClose}>
                        <span className="exit-cross">X</span>
                    </div>
                    <div>
                        <input
                            className="input-field"
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} // Update the email state as the user types
                        />
                        <input
                            className="input-field"
                            type="password"
                            placeholder="Set your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className='format-signup'>
                            <div onClick={checkIfMember} className="signup-button">SUBMIT</div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
}

export default LoginPage;