import React from 'react';
import { NavLink } from 'react-router-dom';
import CurveLogo from './CurveLogo.png'

function MobileMenu({ closeMenu }) {



    return (
        <div className="mobile-menu">
            <ul className="nav-links-mobile">
                <div className="logo-container">
                    {/* <img src={CurveLogo} className="logo" /> */}
                </div>
                <li>
                    <NavLink exact to="/"
                        onClick={closeMenu}
                        className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                        HOME
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        onClick={closeMenu}
                        to="/profile" className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                        PROFILE
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        onClick={closeMenu}
                        to="/membership" className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                        MEMBERSHIP
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        onClick={closeMenu}
                        to="/directory" className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                        DIRECTORY
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        onClick={closeMenu}
                        to="/events" className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                        EVENTS
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        onClick={closeMenu}
                        to="/resources" className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                        RECORDINGS
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/billing" className={({ isActive }) => isActive ? "active-page" : "non-active-class"}>
                        BILLING
                    </NavLink>
                </li>
                <li>
                    <a href="https://www.curve.club/register-guest" target="_blank" rel="noopener noreferrer" className="non-active-class">
                        GUESTS
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default MobileMenu;