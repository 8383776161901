import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
// import './Directory.css';
import './RecordingCard.css';
import clock from './eventslideimg/clock@3x.png'
import calendar from './eventslideimg/calendar-date@3x.png'
import pin from './eventslideimg/marker-pin-01@3x.png'

function RecordingCard(props) {
    const [recording, setRecording] = useState(props.value);

    useEffect(() => {
        const fetchEvents = async () => {

        };
        fetchEvents();
    }, []);


    return (
        <div className={`recording-card ${props.value.isSlide ? 'slide-card' : ''}`}>
            <div className="image-container-recording">
                <img src={recording.coverPhoto} alt={recording.title} />
            </div>
            <div className="text-container-recording">
                <p className="recording-text">{recording.title}</p>
                <p >{recording.description}</p>
            </div>
        </div>
    );
}

export default RecordingCard;