import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './EventCard.css';
import Restaurants from './restaurants';
import Events from './Events';
import './Portal.css'
import './MyEvents.css'
import UpcomingEvents from './UpcomingEvents'
import PastEvents from './PastEvents';
import { onAuthStateChanged, getAuth } from 'firebase/auth';

const auth = getAuth();

function MyEvents() {
    const [events, setEvents] = useState([]);
    const [activeTab, setActiveTab] = useState('upcoming');
    const [user, setUser] = useState('upcoming');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        // Set up the authentication listener
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {

                console.log("user is signed in profile", user)
                // User is signed in
                setUser(user);

                // Perform any necessary actions for a logged-in user
            } else {
                // User is not signed in
                console.log("user is not signed in")
                setUser(null);
                window.location.href = 'https://curve.club';
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchEvents = async () => {

        };

        fetchEvents();
    }, []);

    return (
        <div className='portal-decoration'>
            <h2 className='space-dec'>MY EVENTS</h2>
            <div className="tab-titles">
                <button
                    className={`tab-title ${activeTab === 'upcoming' ? 'active' : ''}`}
                    onClick={() => handleTabClick('upcoming')}
                >
                    UPCOMING EVENTS
                </button>
                <button
                    className={`tab-title ${activeTab === 'past' ? 'active' : ''}`}
                    onClick={() => handleTabClick('past')}
                >
                    PAST EVENTS
                </button>
            </div>

            <div className="tab-content">
                {activeTab === 'upcoming' && <UpcomingEvents />}
                {activeTab === 'past' && <PastEvents />}
            </div>
        </div>
    );
}

export default MyEvents;