// EditProfilePopup.js
import React, { useEffect, useState } from 'react';
import { db, storage } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import './EditProfile.css'
import './General.css'
import OnboardingQuestions from './OnboardingQuestions';

const EditProfilePopup = ({ onClose, value }) => {
    const [member, setMember] = useState();
    const [onboardingData, setOnboardingData] = useState({});
    const [profilePicture, setProfilePicture] = useState(null);
    const [profilePictureDownload, setProfilePictureDownload] = useState('');
    const [name, setName] = useState('');
    // const [onboardingQuestions, setOnboardingQuestions] = useState('');
    const [job, setJobTitle] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [location, setLocation] = useState('');
    const [bio, setBio] = useState('');

    const handleOnboardingDataUpdate = (data) => {
        setOnboardingData(data);
        console.log("SETTING DATE", data)
    };

    const handleProfilePictureChange = async (event) => {
        const file = event.target.files[0];
        console.log("image file", file)
        setProfilePicture(file);
        // Create a reference to the Firebase Storage location where you want to store the image

        console.log("memberid", value)

        const storageRef = storage.ref().child('profile_images/' + value);



        // Upload the image to Firebase Storage
        const snapshot = await storageRef.put(file, {
            contentType: 'image/jpeg' // Set the correct content type here
        });

        // Get the download URL of the uploaded image
        const downloadURL = await snapshot.ref.getDownloadURL();

        console.log('Image uploaded successfully. Download URL:', downloadURL);

        //set the profile picture
        await setProfilePictureDownload(downloadURL)

        member.image = downloadURL

        console.log("sppd", downloadURL)

    };

    const convertSetsToArrays = (obj) => {
        const newObj = {};
        for (const key in obj) {
            if (obj[key] instanceof Set) {
                newObj[key] = Array.from(obj[key]);
            } else {
                newObj[key] = obj[key];
            }
        }
        return newObj;
    };

    const handleSaveChanges = async () => {

        console.log("propsvalue", value)

        const memberRef = db.collection('members').doc(value);

        if (member.image) {
            try {

                console.log("here")

                console.log(companyWebsite, name, bio, location, companyName)

                const convertedProfileQuestions = convertSetsToArrays(onboardingData);

                console.log("converted", convertedProfileQuestions)

                console.log("the new image", profilePictureDownload)


                if (companyWebsite != undefined && name != undefined && bio != undefined && companyName != undefined && location != undefined) {
                    const onboardingDataDict = {
                        displayName: name,
                        bio: bio,
                        company: companyName,
                        title: job,
                        location: location,
                        website: companyWebsite,
                        profile_questions: convertedProfileQuestions,
                        image: member.image
                    };

                    console.log("onboardingdata:", onboardingDataDict)

                    // Update the member data in Firebase Firestore
                    memberRef
                        .update(onboardingDataDict)
                        .then(() => {
                            console.log('Member data updated successfully.');
                            onClose();
                            window.location.reload();
                        })
                        .catch((error) => {
                            console.error('Error updating member data:', error);

                        });
                }
                else {
                    alert("Please fill our all of the fields")
                }




            } catch (error) {

                alert("Please fill in all fields, including your profile picture")

            }




        }



    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const handleChangeJobTitle = (event) => {
        setJobTitle(event.target.value);
    };

    const handleChangeCompanyName = (event) => {
        setCompanyName(event.target.value);
    };

    const handleChangeCompanyWebsite = (event) => {
        setCompanyWebsite(event.target.value);
    };

    const handleChangeLocation = (event) => {
        setLocation(event.target.value);
    };

    const handleChangeBio = (event) => {
        setBio(event.target.value);
    };

    useEffect(() => {
        const fetchMember = async () => {
            try {

                console.log("propsvaleu2", value)

                const memberDoc = await db.collection('members').doc(value).get();

                if (memberDoc.exists) {

                    setMember(memberDoc.data())
                    setJobTitle(memberDoc.data().title)
                    setName(memberDoc.data().name)
                    setCompanyWebsite(memberDoc.data().website)
                    setCompanyName(memberDoc.data().company)
                    setBio(memberDoc.data().bio)
                    setLocation(memberDoc.data().location)
                    setProfilePictureDownload(memberDoc.data().image)
                    setOnboardingData(memberDoc.data().profile_questions)

                }

            }
            catch (error) {
                console.error('Error retrieving events:', error);
            }
        };

        fetchMember();
    }, []);


    return (
        <div className="edit-profile-modal">
            {member && (
                <div className="edit-profile-content">

                    <div className="top-bar">
                        <h2>Edit Profile</h2>
                        <div className="exit-button" onClick={onClose}>
                            <span className="exit-cross">X</span>
                        </div>
                    </div>

                    <div
                        className="square-with-background"
                        style={{
                            backgroundImage: `url(${member.image})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                    </div>

                    <div className='profile-pic-label'>
                        <label htmlFor="profilePictureInput">Edit Profile Picture:</label>
                        <input
                            id="profilePictureInput"
                            type="file"
                            accept="image/*"
                            onChange={handleProfilePictureChange}
                        />

                    </div>


                    <div className="textfield-columns">
                        <div className='textfield-container'>
                            {/* Label */}
                            <label className="input-label">Display Name</label>

                            {/* Text field with placeholder */}
                            <div className="profile-input">
                                <input
                                    type="text"
                                    placeholder="What shall we call you?"
                                    value={name}
                                    onChange={handleChangeName}
                                />
                            </div>
                        </div>

                        <div className='textfield-container'>
                            {/* Label */}
                            <label className="input-label">Job Title</label>

                            {/* Text field with placeholder */}
                            <div className="profile-input">
                                <input
                                    type="text"
                                    placeholder="e.g. CEO, Founder"
                                    value={job}
                                    onChange={handleChangeJobTitle}
                                />
                            </div>
                        </div>

                        <div className='textfield-container'>
                            {/* Label */}
                            <label className="input-label">Company Name</label>

                            {/* Text field with placeholder */}
                            <div className="profile-input">
                                <input
                                    type="text"
                                    placeholder="Curve Club"
                                    value={companyName}
                                    onChange={handleChangeCompanyName}
                                />
                            </div>
                        </div>

                        <div className='textfield-container'>
                            {/* Label */}
                            <label className="input-label">Company Website</label>

                            {/* Text field with placeholder */}
                            <div className="profile-input">
                                <input
                                    type="text"
                                    placeholder="www."
                                    value={companyWebsite}
                                    onChange={handleChangeCompanyWebsite}
                                />
                            </div>
                        </div>

                        <div className='textfield-container-bio'>
                            {/* Label */}
                            <label className="input-label">Location</label>

                            {/* Text field with placeholder */}
                            <div className="profile-input">
                                <input
                                    type="text"
                                    placeholder="e.g. London"
                                    value={location}
                                    onChange={handleChangeLocation}
                                />
                            </div>
                        </div>

                        <div className='textfield-container-bio'>
                            {/* Label */}
                            <label className="input-label">Bio</label>

                            {/* Text field with placeholder */}
                            <div className="profile-input">
                                <input
                                    type="text"
                                    placeholder="This is the about you our members will see"
                                    value={bio}
                                    onChange={handleChangeBio}
                                />
                            </div>
                        </div>

                        <div className='question-container'>


                        </div>

                        <OnboardingQuestions value={member} onUpdate={handleOnboardingDataUpdate}></OnboardingQuestions>

                        <div className='black-button-bottomright' onClick={handleSaveChanges}>SAVE</div>
                    </div>





                </div>
            )}
        </div>
    );
};

export default EditProfilePopup;
