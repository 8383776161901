import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './EventCard.css';
import Restaurants from './restaurants';
import Events from './Events';
import './Profile.css'
import './General.css'
import TabletContainerView from './TabletContainerView';
import EditProfilePopup from './EditProfile';
import { onAuthStateChanged, getAuth } from 'firebase/auth';

const auth = getAuth();

function Profile() {
    const [member, setMember] = useState();
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [user, setUser] = useState();

    const backgroundImage = "https://firebasestorage.googleapis.com/v0/b/curveclub-68421/o/profile_image_placeholder.png?alt=media&token=a0d78aa8-9337-47ac-b1ad-34c35c1a451e"

    const handleEditClick = () => {
        setShowEditPopup(true);
    };

    const handleClosePopup = () => {
        setShowEditPopup(false);
    };

    useEffect(() => {
        // Set up the authentication listener
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {

                console.log("user is signed in", user)
                // User is signed in
                setUser(user);
                fetchMember(user)

                // Perform any necessary actions for a logged-in user
            } else {
                // User is not signed in
                console.log("user is not signed in")
                setUser(null);
                window.location.href = '/login';
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, []);



    const fetchMember = async (user) => {
        if (user != null) { // Assuming userId is set in your component state
            const memberRef = db.collection('members').where('uid', '==', user.uid);


            try {
                const querySnapshot = await memberRef.get();

                if (!querySnapshot.empty) {

                    const document = querySnapshot.docs[0];
                    const member = document.data();
                    member["docId"] = document.id;
                    setMember(member)
                }
            } catch (error) {
                console.error('Error getting member document:', error);
            }
        }
    };



    return (
        <div className='portal-decoration'>
            <h2 className='space-dec'>PROFILE</h2>
            {member && (
                <div className='profile-container'>

                    <div className='col'>
                        {/* IMAGE AND TEXT */}
                        <div
                            className="square-with-background"
                            style={{
                                backgroundImage: `url(${member.image})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            {/* <a href={member.website} target="_blank" rel="noopener noreferrer"> */}
                            <div className="overlay" />
                            <div className="text-container">
                                <p className="text">{member.firstname} {member.lastname}</p>
                                <p className="text">{member.title}</p>
                                <p className="text">{member.company}</p>
                            </div>
                            {/* </a> */}
                        </div>

                        {/* HEADLINE INFORMATION */}
                        <div>
                            <div className="horizontal-line-divider">
                                <hr />
                            </div>

                            <div className='headline-container'>
                                {member.profile_questions !== null && member.profile_questions !== undefined && (
                                    <div className='headline-information'>
                                        <p className="question-text">
                                            {member.membership_zone === 'entrepreneur'
                                                ? 'BUSINESS MODEL'
                                                : member.membership_zone === 'investor'
                                                    ? 'CLASSIFICATION'
                                                    : 'Default Text'}
                                        </p>
                                        <p className="profile-text">
                                            {member.membership_zone === 'entrepreneur'
                                                ? member.profile_questions["Business Type"]
                                                : member.membership_zone === 'investor'
                                                    ? member.profile_questions["Role"]
                                                    : null // or any other fallback value or component if needed
                                            }
                                        </p>
                                    </div>
                                )}

                                {member.profile_questions !== null && member.profile_questions !== undefined && (
                                    <div className='headline-information'>
                                        <p className="question-text">
                                            {member.membership_zone === 'entrepreneur'
                                                ? 'FUNDING STAGE'
                                                : member.membership_zone === 'investor'
                                                    ? 'CAPITAL DEPLOYED'
                                                    : 'Default Text'}
                                        </p>
                                        <p className="profile-text">
                                            {member.membership_zone === 'entrepreneur'
                                                ? member.profile_questions["Funding Stage"]
                                                : member.membership_zone === 'investor'
                                                    ? member.profile_questions["Capital Raised"]
                                                    : null // or any other fallback value or component if needed
                                            }
                                        </p>
                                    </div>
                                )}
                            </div>


                            <div className="horizontal-line-divider">
                                <hr />
                            </div>
                        </div>
                        {/* BIO */}
                        <div>
                            <h5>BIO</h5>
                            <p className="profile-text-bio">{member.bio}</p>
                        </div>

                        <br></br>

                        <div className='black-button' onClick={handleEditClick}>
                            EDIT PROFILE
                        </div>

                        {showEditPopup && <EditProfilePopup onClose={handleClosePopup} value={member.docId} />}


                    </div>
                    {/* 
                    <div className="vertical-line-divider" />

                    {/* SECOND COLUMN */}
                    <div className='col'>
                        <div className="main-container">
                            {Object.entries(member.profile_questions ?? {}).sort((a, b) => a[0].localeCompare(b[0])).map(([question, answers]) => (
                                <div key={question} className="vstack-item">
                                    <h2 className="question-text">{question.toUpperCase()}</h2>
                                    {Array.isArray(answers) ? (
                                        answers.map((answer, index) => (
                                            <TabletContainerView key={index} word={answer} isSelected={false} />
                                        ))
                                    ) : (
                                        <TabletContainerView word={answers} isSelected={false} />
                                    )}
                                </div>
                            ))}
                        </div>


                    </div>

                </div>
            )
            }



        </div >
    );
}

export default Profile;