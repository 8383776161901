import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './EventCard.css';
import Restaurants from './restaurants';
import Events from './Events';
import './Portal.css'
import RecordingSlide from './RecordingSlide'
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import MemberSlide from './MemberSlide';

function Portal() {
    const [events, setEvents] = useState([]);
    const [user, setUser] = useState(null);

    const auth = getAuth();

    useEffect(() => {
        // Set up the authentication listener
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {

                console.log("user is signed in", user)
                // User is signed in
                setUser(user);

                // Perform any necessary actions for a logged-in user
            } else {
                // User is not signed in
                console.log("user is not signed in")
                setUser(null);
                window.location.href = '/login';
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchEvents = async () => {

        };

        fetchEvents();
    }, []);



    return (
        <div className='portal-decoration'>
            <h2 className='space-dec'>THE MEMBERS PORTAL</h2>

            <p>BOOK A TABLE</p>
            <Restaurants></Restaurants>

            <div className='discounts-background'>
                <div className="discounts-content">
                    <div className="discounts-text">
                        <p className='discounts-title'>DISCOUNTS & DEALS</p>
                        <p>Enjoy exclusive perks, discounts, and access to special deals as a valued member.</p>
                    </div>
                    <a href="https://periodic-session-f00.notion.site/Curve-Club-Discounts-Deals-30daff974a544282ba5710bc924d8c0c" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <div className="black-button">DISCOVER</div>
                    </a>
                </div>
            </div>

            <p>UPCOMING EVENTS</p>
            <Events value={user}></Events>
            <div className='information-row'>
                <p>Speaker Series Recordings</p>
                <Link to={`/resources`} className="seemore-button">
                    SEE MORE
                </Link>
            </div>

            <RecordingSlide></RecordingSlide>

            <div className='information-row'>
                <p>EXPLORE OUR MEMBERS</p>
                <Link to={`/directory`} className="seemore-button">
                    SEE MORE
                </Link>
            </div>

            <MemberSlide></MemberSlide>

        </div >
    );
}

export default Portal;