import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './Directory.css';
import './MemberDirectoryCard.css';
import clock from './eventslideimg/clock@3x.png'
import calendar from './eventslideimg/calendar-date@3x.png'
import pin from './eventslideimg/marker-pin-01@3x.png'

function MemberDirectoryCard(props) {
    const [member, setMember] = useState(props.value);

    useEffect(() => {
        const fetchEvents = async () => {
        };
        fetchEvents();
    }, []);


    return (
        <div className='member-card' style={{
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: '100px', // Add this line for border radius
        }}>
            <div
                className="square-with-background-directory"
                style={{
                    backgroundImage: `url(${member.image || 'https://firebasestorage.googleapis.com/v0/b/curveclub-68421/o/profile_image_placeholder.png?alt=media&token=a0d78aa8-9337-47ac-b1ad-34c35c1a451e'})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >

                <div className="text-container-directory">
                    <p className="textLarge">{member.firstname} {member.lastname}</p>

                    <p className="text">{member.company}</p>
                </div>
                <div className="pill-container">
                    <p
                        className="text pill-background"
                        style={{
                            backgroundColor: member.member_zone === 'investor' ? 'black' : 'white',
                            color: member.member_zone === 'investor' ? 'white' : '#696136'
                        }}
                    >
                        {member.profile_questions && member.profile_questions["Professional Classification"] && member.profile_questions["Professional Classification"].length > 0
                            ? member.profile_questions["Professional Classification"][0].toUpperCase()
                            : "FOUNDER"
                        }
                    </p>
                </div>

            </div>
        </div>
    );
}

export default MemberDirectoryCard;