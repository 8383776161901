import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
// import './Directory.css';
import './MemberExploreCard.css';
import clock from './eventslideimg/clock@3x.png'
import calendar from './eventslideimg/calendar-date@3x.png'
import pin from './eventslideimg/marker-pin-01@3x.png'

function MemberExploreCard(props) {
    const [member, setMember] = useState(props.value);

    useEffect(() => {
        const fetchEvents = async () => {

        };
        fetchEvents();
    }, []);

    function openWebsiteInNewTab(websiteUrl) {
        // Prepend 'http://' if it's not already included
        if (!websiteUrl.startsWith('http://') && !websiteUrl.startsWith('https://')) {
            websiteUrl = 'http://' + websiteUrl;
        }

        window.open(websiteUrl, '_blank');
    }




    return (
        <div className={`memberexplore-card ${props.value.isSlide ? 'slide-card' : ''}`}>
            <div className="image-container-memberexplore">
                <img src={member.image} alt={member.id} />
            </div>
            <div className="text-container-recording">
                <Link to={{
                    pathname: `/member/${member.id}`,
                    state: { member } // Pass the memberData object as state directly
                }}

                    style={{
                        "text-decoration": "none"
                    }}

                    key={member.id}>
                    <p className="memberexplore-text">{`${member.firstname} ${member.lastname}`}</p>
                </Link>
                <p className='company-text'>
                    <a className='company-link' href="#" onClick={() => openWebsiteInNewTab(member.website)}>
                        {member.company}
                    </a>
                </p>
            </div>
        </div>
    );
}

export default MemberExploreCard;