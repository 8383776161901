import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './EventCard.css';
import './Membership.css'
import './General.css'
import { onAuthStateChanged, getAuth } from 'firebase/auth';

const auth = getAuth();

function Membership() {
    const [events, setEvents] = useState([]);
    const [member, setMember] = useState();
    const [user, setUser] = useState();

    const backgroundImage = "https://firebasestorage.googleapis.com/v0/b/curveclub-68421/o/MemershipCardTemplate.png?alt=media&token=5ce366db-2b0c-4480-8618-9921d314e7dc"

    useEffect(() => {
        // Set up the authentication listener
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {

                console.log("user is signed in profile", user)
                // User is signed in
                setUser(user);
                fetchMember(user)

                // Perform any necessary actions for a logged-in user
            } else {
                // User is not signed in
                console.log("user is not signed in")
                setUser(null);
                window.location.href = 'https://curve.club';
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, []);



    const fetchMember = async (user) => {

        const memberRef = db.collection('members').where('uid', '==', user.uid);


        try {
            const querySnapshot = await memberRef.get();

            if (!querySnapshot.empty) {

                const document = querySnapshot.docs[0];
                const member = document.data();
                console.log("the member", member)
                setMember(member)
            }
        } catch (error) {
            console.error('Error getting member document:', error);
        }

    };



    return (
        <div className='portal-decoration'>
            <h2 className='space-dec'>MEMBERSHIP</h2>
            {member && (
                <div className='membership-card'>

                    <div
                        className="membership-card-image"
                        style={{
                            backgroundImage: `url(${backgroundImage})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                        <div className="membership-card-container">
                            <p className="membership-card-text">CURVE CLUB</p>
                            <div>
                                <p className="membership-card-small-text">Membership Number</p>
                                <p className="membership-number">{member.memberId}</p>
                            </div>

                        </div>
                    </div>

                </div>
            )}

            {member && (
                <div>
                    <p className="small-bold-text">MEMBER SINCE</p>
                    <p className="membership-card-text">{member.memberSince}</p>

                    <p className="small-bold-text">MEMBERSHIP NUMBER</p>
                    <p className="membership-card-text">{member.memberId}</p>

                    <p className="small-bold-text">MEMBERSHIP TYPE</p>
                    <p className="membership-card-text" style={{ textTransform: 'uppercase' }}>{member.membership}</p>

                    <p className="small-bold-text">EMAIL</p>
                    <p className="membership-card-text">{member.email}</p>

                </div>
            )}
            <br></br>
            <p className="small-bold-text">Need support? Email memberships@curve.club</p>


        </div>
    );
}

export default Membership;