import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './EventCard.css';
import './Membership.css'
import './General.css'
import './MemberExploreCard'
import RecordingCard from './RecordingCard'
import MemberExploreCard from './MemberExploreCard';

function MemberSlide() {
    const [members, setMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchNewMembers = async () => {
            setIsLoading(true);
            const twoWeeksAgo = new Date();
            twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 28);

            const firestoreTimestamp = firebase.firestore.Timestamp.fromDate(twoWeeksAgo);

            try {
                const querySnapshot = await firebase.firestore()
                    .collection('members')
                    .where('active', '==', true)
                    .get();

                const members = querySnapshot.docs.map((doc) => {
                    const data = doc.data();
                    const { firstname, lastname, membership, email, memberId, proposer_code } = data;
                    const { title, company, image, bio, website, displayName, onboarded, profile_questions } = data;

                    if (profile_questions) {
                        return {
                            id: doc.id,
                            uid: '',
                            firstname,
                            lastname,
                            membershipType: membership,
                            email,
                            memberId,
                            proposerCode: proposer_code,
                            bio,
                            website,
                            company,
                            title,
                            image,
                            displayName,
                            onboarded,
                            profile: profile_questions || {},
                        };
                    } else {
                        return null;
                    }
                }).filter((member) => member !== null);

                const shuffledMembers = members.sort(() => Math.random() - 0.5);
                const selectedMembers = shuffledMembers.slice(0, 6);

                setIsLoading(false);
                setMembers(selectedMembers);
            } catch (error) {
                console.error('Error fetching documents:', error.message || 'Unknown error');
                setIsLoading(false);
            }
        };

        fetchNewMembers();
    }, []);

    return (
        <div className="memberexplore-slide">
            {members.map((member, index) => (
                <MemberExploreCard key={index} value={member}></MemberExploreCard>
            ))}
        </div>
    );
}

export default MemberSlide;